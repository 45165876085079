export const Face = require('./f.png');
export const Twitter = require('./t.png');
// export const In = require('./link.png');
export const Ins = require('./ins.png');
export const Logo = require('./logo.png');
export const How1 = require('./how1.png');
export const How2 = require('./how2.png');
export const How3 = require('./how3.png');
export const Hero = require('./hero.png');
export const Autonew = require('./autorenew.png');
export const Lap = require('./laptop_mac.png');
export const Clock = require('./clock.png');
export const Vimage = require('./vimg.png');
export const Fimage = require('./fimg.png');
export const Ahero = require('./mana.png');
export const V1 = require('./v1.png');
export const V2 = require('./v2.png');

export const Lif = require('./lif.png');
export const Lib = require('./lilb.png');
export const Liins = require('./lilins.png');
export const Mark = require('./marker.png');
export const Phone = require('./phone.png');
export const Mail = require('./env.png');
export const ProImage = require('./pimg.png');
export const Reach = require('./reach.png');
export const What = require('./whatsap.png');
export const Ci = require('./ci.png');
export const Ci2 = require('./ci2.png');
export const Pi = require('./Pi.png');
export const Pi2 = require('./Pi2.png');
export const Fav = require('./fav.png');
export const Au = require('./au.png');
export const Te = require('./Te.png');












